<template>
    <div>
        <div class="bgc-img">
                <div class="cenji">
                    <div class="row">
                        <img class="text-img" src="../assets/hint.png" alt="">
                        <div class="content">
                            <div class="merchant btn-row" @click="$router.push('/merchant')">
                                <img src="../assets/sj.png" alt="">
                                <span>我是商家</span>
                            </div>
                            <div class="expert btn-row" @click="$router.push('/intelligent')">
                                <img src="../assets/dr.png" alt="">
                                <span>我是达人</span>
                            </div>
                            <div class="paikew btn-row" @click="$router.push('/login')">
                                <img src="../assets/pk.png" alt="">
                                <span>我是拍客</span>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "content"
    }
</script>

<style scoped lang="scss">
    .row{
        width: 120px;
        position: absolute;
        top:0;
        left:0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
    .text-img{
        position: absolute;
        top: 200px;
        left: 50%;
        transform:translateX(-50%);
        height: 120px;
        z-index: 1000;
    }
    .bgc-img{
        position: relative;
        background:url("../assets/home-bg.jpg") no-repeat;
        background-size: cover;
        min-height: calc(100vh - 80px);
        background-position: 0 -80px;
        min-width: 1200px;
    }
    .cenji{
        position: absolute;
        width: 100%;
        min-height: calc(100vh - 80px);
        background-color: rgba(0,0,0,.4);
        z-index: 10;
    }

    .btn-row{
        display: flex;
        padding: 10px 30px;
        background-color: dodgerblue;
        color: white;
        margin: 0 10px;
        border-radius: 10px;
        cursor: pointer;
        white-space: nowrap;
        img{
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
.content{
    position: absolute;
    display: flex;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-60%);
    z-index: 100;
}
</style>
